import React, { useEffect, useState, useRef } from 'react'
import { navigate } from 'gatsby'
import { Button, Popup } from 'semantic-ui-react'
// Components
import { Loading, PageHeader, Seo } from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { usePrismic } from '../context/PrismicContext'
// Styles
import { Container, Wrapper } from '../styles/common'
import {
  AssociateItem,
  AssociateType,
  ContactInfoItem,
  ContactInfoItemImg,
  ContactInfoItemValue,
  ProfileImg,
  UserName,
} from '../styles/Profile.styles'

const ProfilePage = () => {
  const [showCopied, setShowCopied] = useState(false)
  const [open, setOpen] = useState(false)
  const popupRef = useRef()
  const { qUser, isAuthenticated } = useAuthContext()
  const {
    prismicData: {
      prismicProfilePage: {
        communication_type_icon,
        default_image,
        email_icon,
        email,
        loading,
        new_user,
        page_title,
        phone_number_icon,
        profile_header,
        text,
        unavailable,
        username,
      },
    },
  } = usePrismic()

  useEffect(() => {
    if (!isAuthenticated) navigate('/')
  }, [])

  const profileImgUrl = () => {
    if (qUser?.profileImageFileName) return qUser.profileImageFileName

    return default_image.url
  }

  const showCommTypes = () => {
    if (qUser.receiveEmail && qUser.receiveText)
      return `${email[0].text}/${text[0].text}`
    if (qUser.receiveEmail && !qUser.receiveText) return `${email[0].text}`
    return `${text[0].text}`
  }

  const copyToClipboard = str => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setShowCopied(true)
      return navigator.clipboard.writeText(str)
    }
    return Promise.reject('The Clipboard API is not available.')
  }

  if (!qUser) return <Loading loading={true} message={loading[0].text} />

  return (
    <>
      <Seo title={page_title[0].text} />
      <Wrapper>
        <PageHeader exitRoute="/">{profile_header[0].text}</PageHeader>
        <Container align="center" padded>
          <ProfileImg data-qa="profileImage" src={profileImgUrl()} />
        </Container>

        <Container align="center" padded>
          <UserName data-qa="profileUsername">
            {qUser.displayName || new_user[0].text}
          </UserName>
          <AssociateType>
            <AssociateItem bold data-qa="profileAssociateType">
              {qUser?.associateType}
            </AssociateItem>
            <AssociateItem bold data-qa="profileAssociateID">
              &nbsp;|&nbsp;{qUser?.legacyAssociateId}
            </AssociateItem>
          </AssociateType>

          {qUser?.associateType === 'AMBASSADOR' ? (
            <>
              {/* <AssociateType>
                <AssociateItem data-qa="profileAssociateUsername">
                  DS username: {qUser?.dS_Username}
                </AssociateItem>
              </AssociateType> */}
              <AssociateType>
                {/* <Popup
                  content="URL copied to clipboard"
                  context={popupRef}
                  hideOnScroll={true}
                  position="top center"
                  style={{ fontWeight: 'bold' }}
                  trigger={showCopied === true}
                /> */}
                <AssociateItem slug data-qa="profileAssociateSlug">
                  {`shopq.qsciences.com/store/${qUser?.associateSlug}`}
                  <Popup
                    content="Copied!"
                    eventsEnabled={showCopied}
                    on="click"
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    hideOnScroll
                    onMount={() => setTimeout(() => setOpen(false), 2000)}
                    open={open}
                    trigger={
                      <Button
                        className="copy-button"
                        basic
                        compact
                        icon="clipboard"
                        ref={popupRef}
                        onClick={() =>
                          copyToClipboard(
                            `shopq.qsciences.com/store/${qUser?.associateSlug}`
                          )
                        }
                      />
                    }
                  />
                </AssociateItem>
              </AssociateType>
            </>
          ) : null}
        </Container>
        <Container align="center" padding="2em 1em 1em 1em">
          <ContactInfoItem>
            <ContactInfoItemImg src={phone_number_icon.url} />
            <ContactInfoItemValue data-qa="profilePhoneNumber">
              {qUser.primaryPhoneNumber || unavailable[0].text}
            </ContactInfoItemValue>
          </ContactInfoItem>
          <ContactInfoItem>
            <ContactInfoItemImg src={email_icon.url} />
            <ContactInfoItemValue data-qa="profileEmail">
              {qUser?.uniqueEmailAddress ?? unavailable[0].text}
            </ContactInfoItemValue>
          </ContactInfoItem>
          <ContactInfoItem>
            <ContactInfoItemImg src={communication_type_icon.url} />
            <ContactInfoItemValue data-qa="profileCommunication">
              {showCommTypes()}
            </ContactInfoItemValue>
          </ContactInfoItem>
        </Container>
      </Wrapper>
    </>
  )
}

export default ProfilePage
